:root {
	/* Dark Mode */
	--background-color: #000000;
	--text-primary: #ffffff;
	--text-secondary: #ffffff;
	--slogan: #ffffff;
	--button-bg: #cacaca;
	--button-text: #000000;
	--neutral: #333333;
	--border: #8a8a8a;
	--shadow: hsla(0, 0%, 100%, 0.2);
	--text-shadow: #222;
}

[data-theme="light"] {
	/* Light Mode */
	--background-color: #ffffff;
	--text-primary: #ffffff;
	--text-secondary: #ffffff;
	--slogan: #ffffff;
	--button-bg: #000000;
	--button-text: #ffffff;
	--neutral: #dddddd;
	--border: #4b4b4b;
	--shadow: hsla(0, 0%, 20%, 0.2);
	--text-shadow: #222;
}

@font-face {
	font-family: 'Consolas Italic'; /*a name to be used later*/
	src: url('/public/Consolas-Font/Consolas.ttf'); /*URL to font*/
}

@font-face {
	font-family: 'Consolas'; /*a name to be used later*/
	src: url('/public/Consolas-Font/CONSOLA.TTF'); /*URL to font*/
}

.App {
	font-family: 'Consolas';
	min-height: 100vh;
	width: 100vw;
	background-color: var(--background-color);
	transition: all 0.5s;
}

