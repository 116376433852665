@import '../../App.css';

.home-wrapper {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-height: 100%;
	width: 100%;
	gap: 20px;
}

.home-container {
	display: flex;
	flex-direction: column;
	width: 100%;
	gap: 10px;
}

.home-title {
	text-align: center;
	color: var(--text-primary)
}
